#navBarId {
  margin-bottom: 30px;
}

.nav-logo {
  animation: hideshow 10s ease 1;
  height: 22px;
  width: 140px;
  margin: auto;
}

button.navbar-toggler:focus {
  outline: 2px snow solid !important;
}

.navbar-brand {
  animation: hideshow 10s ease 1;
  margin-top: 15px;
}

.navbar-nav {
  animation: hideshow 13s ease 1;
}

h6, .h6 {
  font-size: 0.8rem !important;
  margin-top: -10px !important;
}

#dropdown-menu-settings {
  position: absolute;
  top: 72%;
  right: 30px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

/* FOR IE 10 or Higher only */
/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nav-logo {
       margin-left: -70px;
  }
} */

@keyframes hideshow {
  0% { opacity: 0; }
  3% { opacity: 0; }
  15% { opacity: 1; }
  100% { opacity: 1; }
} 