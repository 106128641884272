body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 0.9em !important;
}

.form-control {
  font-size: 0.8rem !important;
}

.nav-logo {
  margin-bottom: 6px !important;
}

.navbar-brand {
  font-size: 1.2rem !important;
}

/* .card {
  max-width: 820px !important;
} */