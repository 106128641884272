.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #41464b !important;
}

.nav-pills a{
  color: #949494 !important;
}

.nav-pills a:hover{
  color: #41464b !important;
}
