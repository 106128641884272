textarea {
  resize: none;
  }

.app {
  text-align: center;
}

.app-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.app-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.app-title {
  font-size: 1.5em;
}

.app-intro {
  font-size: large;
}

#deleteIconBtn svg:hover path {
  fill: red;
  transition: all ease 0.3s;
}

#editIconBtn svg:hover path {
  fill: blue;
  transition: all ease 0.3s;
}

.input-group-text {
  font-size: inherit !important;
}

.mb-3 {
  height: 33px;
}

.dashboardCard {
  float: left !important;
  width: 300px;
  font-family: Helvetica,Arial,sans-serif !important;
  border: 0;
  padding: 0;
  display: flex;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  font-size: .875rem;
  margin-top: 30px;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 30px;
  border-radius: 6px;
  flex-direction: column;
}

.dashboardSpacer {
  float: left !important;
}

.dashboardItemCard {
  height: 125px; 
  padding: 0 10px 0 10px;
}

.dashboardLineText {
  color: #999;
  font-size: 12px;
  margin: 0 10px 0 10px;
  line-height: 22px;
  border-top: 1px solid #e8e8e8;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dashboardIconCard {
  float: left;
  display: block;
  padding: 15px;
  margin-top: -20px;
  margin-left: 15px;
  border-radius: 3px; 
  background-color: #999;
}

.green {
  background: linear-gradient(60deg, #66bb6a, #43a047);
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}

.yellow {
  background: linear-gradient(60deg, #ecf01f, #e7e302);
  box-shadow: 0 12px 20px -10px rgba(173, 175, 76, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}

.red {
  background: linear-gradient(60deg, #f03e1f, #e70202);
  box-shadow: 0 12px 20px -10px rgba(175, 76, 76, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}

.orange {
  background: linear-gradient(60deg, #f0731f, #e77102);
  box-shadow: 0 12px 20px -10px rgba(175, 124, 76, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}

.purple {
  background: linear-gradient(60deg, #b51ff0, #8b02e7);
  box-shadow: 0 12px 20px -10px rgba(130, 76, 175, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
}

.dashboardPText {
  color: #999999;
  margin: 0 !important;
  font-size: 18px;
  margin-top: 0;
  padding-top: 5px;
  margin-bottom: 0;
  height:54px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  text-align: right !important;

}

.dashboardHText {
  display: block;
  color: #999;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: right !important;
}

/* FOR IE 10 or Higher only */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mb-3 {
    height: 30px !important;
  }
}

.modal {
  text-align: center;
  padding: 0!important;
  }
  
  .modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
  }

  .modal-backdrop {
    opacity: 0.5 !important;
  }

  @media screen and (min-width: 1200px) {
    .modal-dialog {
      max-width: 900px !important;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .modal-dialog {
      max-width: 700px !important;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }
  
  @media (min-width: 576px) and (max-width: 768px) {
    .modal-dialog {
      max-width: 500px !important;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }

  @media (max-width: 576px) {
    .modal-dialog {
      max-width: 400px !important;
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
